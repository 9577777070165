import { Link } from "gatsby"
import React from "react"
import { Row } from "react-bootstrap"
import SVGIcon from "../common/SVGIcon"

const MillionCard = props => {
  const {
    episodeNumber,
    audioDuration,
    title,
    season,
    slug,
    image,
    guestName,
    guestTitle,
    superPodGuestTextTheme,
  } = props.data
  const slugprefix =
    props.slug === "superpod-bytes" || props.slug === "limited-edition"
      ? props.slug
      : `superpod/${props.slug}`
  return (
    <Link to={`/${slugprefix}/${slug}`}>
      <div
        className={`episode-card-million episode-cards-common ${props.width_100 &&
          "w-100"}`}
      >
        <Row>
          <center>
            <div
              className={`cover-container prelative overflow-hidden ${props.isFeaturedEpisode &&
                "h245"}`}
            >
              <img src={image.url} className="cover w-100 h-100" />
              {superPodGuestTextTheme != null && (
                <div
                  class={`speaker-about-container position-absolute ${superPodGuestTextTheme.toLowerCase()}`}
                >
                  <h3>{guestName}</h3>
                  <p className="fw-bold">{guestTitle}</p>
                </div>
              )}
              <div className="img-overlay w-100 h-100 position-absolute">
                <div className="play-icon prelative">
                  <SVGIcon name="superpod/playicon-pink" />
                </div>
              </div>
            </div>
          </center>
        </Row>
        <Row>
          <div className="content">
            <div>
              <div className="episode">
                {season && <span className="e-season">{season}: </span>}
                <span className="e-no">EPISODE {episodeNumber}</span>
              </div>
              {audioDuration && (
                <span className="e-duration">{audioDuration} MINS</span>
              )}
            </div>
            <span className="e-title">{title}</span>
          </div>
        </Row>
      </div>
    </Link>
  )
}

export default MillionCard
